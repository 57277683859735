import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询电子围栏列表
export function listAreaManager(query) {
  return request({
    url: '/area/manager/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 新增电子围栏
export function addAreaManager(data) {
  return request({
    url: '/area/manager',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改电子围栏
export function updateAreaManager(data) {
  return request({
    url: '/area/manager',
    method: 'put',
    baseURL,
    data: data
  })
}
// 启用|禁用电子围栏
export function enableAreaManager(data) {
  return request({
    url: '/area/manager/enable',
    method: 'post',
    baseURL,
    data: data
  })
}
// 删除电子围栏
export function delAreaManager(id) {
  return request({
    url: '/area/manager/' + id,
    baseURL,
    method: 'delete'
  })
}

// 获取电子围栏详细信息
export function getAreaManager(id) {
  return request({
    url: '/area/manager/' + id,
    baseURL,
    method: 'get'
  })
}

// 电子围栏即时在岗情况
export function getAreaOnDutyByAreaIds(ids) {
  return request({
    url: '/area/manager/onduty/' + ids,
    baseURL,
    method: 'get'
  })
}
